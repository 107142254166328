:root {
    --primary-50-d: #007284;
    --primary: #1890ff;
    /* --primary: #007F93; */
    --primary-50-l: #80BFC9;
    --primary-10-l: #E6F3F5;
    --primary-5-l: #F2F8F9;

    --black: #000000;
    --grey-900: #212529;
    --grey-800: #343A40;
    --grey-700: #495057;
    --grey-600: #6C757D;
    --grey-500: #ADB5BD;
    --grey-400: #CED4DA;
    --grey-300: #DEE2E6;
    --grey-200: #E9ECEF;
    --grey-100: #F8F9FA;
    --white: #FFFFFF;

    --success: #28A745;
    --warning: #FFC107;
    --danger: #DC3545;
    --info: #007BFF;

    --blue: #007BFF;
    --indigo: #6610F2;
    --purple: #6F42C1;
    --pink: #E83E8C;
    --red: #DC3545;
    --orange: #FD7E14;
    --yellow: #FFC107;
    --green: #28A745;
    --teal: #20C997;
}

.b--grey-200 {
    border-color: var(--grey-200);
}
