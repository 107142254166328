*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none !important;
}

.visible {
  visibility: visible !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-8 {
  bottom: 2rem !important;
}

.left-1\/2 {
  left: 50% !important;
}

.left-8 {
  left: 2rem !important;
}

.top-0 {
  top: 0px !important;
}

.top-1\/2 {
  top: 50% !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-5 {
  z-index: 5 !important;
}

.z-\[1050\] {
  z-index: 1050 !important;
}

.z-auto {
  z-index: auto !important;
}

.m-0 {
  margin: 0px !important;
}

.m-auto {
  margin: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-1\.5 {
  margin-left: 0.375rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.box-content {
  box-sizing: content-box !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.grid {
  display: grid !important;
}

.hidden {
  display: none !important;
}

.h-64 {
  height: 16rem !important;
}

.h-auto {
  height: auto !important;
}

.h-full {
  height: 100% !important;
}

.h-max {
  height: -webkit-max-content !important;
  height: max-content !important;
}

.max-h-128 {
  max-height: 32rem !important;
}

.max-h-36 {
  max-height: 9rem !important;
}

.max-h-64 {
  max-height: 16rem !important;
}

.min-h-12 {
  min-height: 3rem !important;
}

.min-h-32 {
  min-height: 8rem !important;
}

.min-h-36 {
  min-height: 9rem !important;
}

.min-h-64 {
  min-height: 16rem !important;
}

.w-0 {
  width: 0px !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-80 {
  width: 20rem !important;
}

.w-9\/12 {
  width: 75% !important;
}

.w-auto {
  width: auto !important;
}

.w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.w-full {
  width: 100% !important;
}

.min-w-32 {
  min-width: 8rem !important;
}

.min-w-64 {
  min-width: 16rem !important;
}

.min-w-80 {
  min-width: 20rem !important;
}

.max-w-112 {
  max-width: 28rem !important;
}

.max-w-128 {
  max-width: 32rem !important;
}

.max-w-256 {
  max-width: 48rem !important;
}

.max-w-32 {
  max-width: 8rem !important;
}

.max-w-512 {
  max-width: 64rem !important;
}

.max-w-64 {
  max-width: 16rem !important;
}

.max-w-8 {
  max-width: 2rem !important;
}

.max-w-80 {
  max-width: 20rem !important;
}

.max-w-96 {
  max-width: 24rem !important;
}

.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.shrink-0 {
  flex-shrink: 0 !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.grow {
  flex-grow: 1 !important;
}

.grow-0 {
  flex-grow: 0 !important;
}

.transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.grid-flow-col {
  grid-auto-flow: column !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-autofitCards {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-2\.5 {
  gap: 0.625rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.gap-8 {
  gap: 2rem !important;
}

.gap-x-1 {
  -webkit-column-gap: 0.25rem !important;
          column-gap: 0.25rem !important;
}

.gap-x-3 {
  -webkit-column-gap: 0.75rem !important;
          column-gap: 0.75rem !important;
}

.gap-x-4 {
  -webkit-column-gap: 1rem !important;
          column-gap: 1rem !important;
}

.gap-y-1 {
  row-gap: 0.25rem !important;
}

.gap-y-2 {
  row-gap: 0.5rem !important;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}

.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
}

.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid !important;
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity)) !important;
}

.self-start {
  align-self: flex-start !important;
}

.self-end {
  align-self: flex-end !important;
}

.self-center {
  align-self: center !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-pre {
  white-space: pre !important;
}

.break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-all {
  word-break: break-all !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.border {
  border-width: 1px !important;
}

.border-x-0 {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

.border-b-0 {
  border-bottom-width: 0px !important;
}

.border-l-0 {
  border-left-width: 0px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-none {
  border-style: none !important;
}

.bg-\[\#003C5B\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 60 91 / var(--tw-bg-opacity)) !important;
}

.bg-disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.bg-neutral-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.bg-sky-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity)) !important;
}

.bg-sky-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity)) !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.object-cover {
  object-fit: cover !important;
}

.object-scale-down {
  object-fit: scale-down !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-1\.5 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.italic {
  font-style: italic !important;
}

.leading-7 {
  line-height: 1.75rem !important;
}

.leading-auto {
  line-height: auto !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-blue-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
}

.text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.text-inherit {
  color: inherit !important;
}

.text-lime-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(132 204 22 / var(--tw-text-opacity)) !important;
}

.text-neutral-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(115 115 115 / var(--tw-text-opacity)) !important;
}

.text-orange-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 115 22 / var(--tw-text-opacity)) !important;
}

.text-pink-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(236 72 153 / var(--tw-text-opacity)) !important;
}

.text-primary {
  --tw-text-opacity: 1 !important;
  color: rgb(24 144 255 / var(--tw-text-opacity)) !important;
}

.text-purple-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(168 85 247 / var(--tw-text-opacity)) !important;
}

.text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}

.text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-yellow-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(234 179 8 / var(--tw-text-opacity)) !important;
}

.underline {
  -webkit-text-decoration-line: underline !important;
          text-decoration-line: underline !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.blur-xs {
  --tw-blur: blur(2px) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.transition-colors {
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.duration-\[4000ms\] {
  transition-duration: 4000ms !important;
}

.will-change-auto {
  will-change: auto !important;
}

html,
body,
#root {
  height: 100%;
  /* overflow: hidden; */
}

.left-menu > div {
  width: 100%;
  height: 100%;
}

.ProseMirror > p {
  margin: inherit !important;
}

div * {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

:focus,
:active {
  outline: none !important;
}

::-webkit-scrollbar-track {
  box-shadow: none !important;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  padding: 5px;
  border: none;
  background-color: var(--grey-200);
}

.ant-tabs-content {
  height: 100%;
}

.ant-input[disabled],
.ant-input-number-input[disabled],
.ant-picker-input > input[disabled],
.ant-select-disabled .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selection-item,
.ant-checkbox-disabled + span,
.ant-radio-button-disabled + span {
  color: var(--grey-900) !important;
}

.ant-badge {
  line-height: 1rem !important;
}

ul.ant-menu-horizontal {
  line-height: 45px;
}

ul.ant-menu-overflow.ant-menu-horizontal > li.ant-menu-overflow-item {
  margin-top: 0;
  top: 0;
}

.ant-page-header-heading {
  gap: 0.25rem;
}

.ant-table-body {
  height: 100vh;
}

@media screen and (max-width: 30em) {
  .listing-header .ant-page-header-heading-left,
  .listing-header .ant-page-header-heading-title {
    flex: 1 1 auto;
    width: auto;
  }

  .ant-page-header-heading-left,
  .ant-page-header-heading-title {
    width: 100%;
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.menu-item-overflow-hidden > .ant-dropdown-menu-title-content {
  overflow: hidden;
}

.menu-item-group-no-margin > .ant-dropdown-menu-item-group-list {
  margin: 0;
}

.active-text-color-list-item {
  color: var(--primary);
}

.active-color {
  color: var(--primary) !important;
}

.hover-background-primary:hover,
.active-background {
  background-color: var(--primary);
}

.innactive {
  color: var(--grey-300) !important;
}

.ant-table-title-padding .ant-table.ant-table-middle .ant-table-title {
  border: none;
  padding: 0;
}

.tabs-no-margin.ant-tabs > .ant-tabs-nav {
  margin: 0;
}

.tabs-centered.ant-tabs > .ant-tabs-nav {
  width: calc(100% - 2rem);
  margin: 0 auto;
}

.ant-avatar img,
.ant-comment-avatar img {
  width: inherit !important;
  height: inherit !important;
}

.ant-form-vertical .ant-form-item {
  margin-bottom: 0.825rem;
}

pre {
  font-size: inherit;
  white-space: pre;
  font-family: inherit;
}

.ant-radio-group.ant-radio-group-solid.flex label.ant-radio-button-wrapper {
  flex: 1;
}

.ant-collapse.compact > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0;
}

.ant-modal.hide-modal-title-border div.ant-modal-header {
  border-bottom: none;
}

.ant-form-item-explain-error {
  white-space: pre-wrap;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center;
}

.segmented-item-padding-0 .ant-segmented-item-label {
  padding: 0;
  display: flex;
  justify-content: center;
}

.ant-list.empty-list-no-image .ant-list-empty-text {
  padding: 0;
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

@media (min-width: 640px) {
  .sm\:w-4\/5 {
    width: 80% !important;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:flex-row {
    flex-direction: row !important;
  }

  .sm\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block !important;
  }

  .md\:w-2\/4 {
    width: 50% !important;
  }

  .md\:w-2\/5 {
    width: 40% !important;
  }

  .md\:w-2\/6 {
    width: 33.333333% !important;
  }

  .md\:w-3\/6 {
    width: 50% !important;
  }

  .md\:max-w-96 {
    max-width: 24rem !important;
  }

  .md\:flex-auto {
    flex: 1 1 auto !important;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:items-start {
    align-items: flex-start !important;
  }

  .md\:gap-16 {
    gap: 4rem !important;
  }

  .md\:gap-6 {
    gap: 1.5rem !important;
  }

  .md\:self-center {
    align-self: center !important;
  }

  .md\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:text-4xl {
    font-size: 2.25rem !important;
    line-height: 2.5rem !important;
  }

  .md\:text-6xl {
    font-size: 3.75rem !important;
    line-height: 1 !important;
  }
}

@media (min-width: 1024px) {
  .lg\:relative {
    position: relative !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:w-1\/4 {
    width: 25% !important;
  }

  .lg\:w-1\/5 {
    width: 20% !important;
  }

  .lg\:w-3\/4 {
    width: 75% !important;
  }

  .lg\:w-3\/6 {
    width: 50% !important;
  }

  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:min-w-64 {
    min-width: 16rem !important;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:flex-row {
    flex-direction: row !important;
  }

  .lg\:flex-col {
    flex-direction: column !important;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lg\:justify-center {
    justify-content: center !important;
  }

  .lg\:gap-44 {
    gap: 11rem !important;
  }

  .lg\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
  }

  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
  }

  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid !important;
  }

  .lg\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1 !important;
    border-color: rgb(229 231 235 / var(--tw-divide-opacity)) !important;
  }

  .lg\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000 !important;
    --tw-shadow-colored: 0 0 #0000 !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  }
}
